import { googlePlayLink, appStoreLink } from "./config";

export const allowedUTMParams = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "referrer",
  "google_aid",
  "click_id",
  "google_aid_sha1",
  "google_aid_md5",
  "ios_ifa",
  "ios_ifa_sha1",
  "ios_ifa_md5",
  "oaid",
  "oaid_sha1",
  "oaid_md5",
  "windows_aid",
  "windows_aid_sha1",
  "windows_aid_md5",
  "device_ip",
  "device_ua",
  "click_timestamp",
  "noredirect",
  "afpub_id",
  "site_id",
  "creative_id",
  "appmetrica_js_redirect"
];

export const stores = {
  appStore: {
    link: appStoreLink,
    imageUrl: "https://static.tildacdn.com/tild6539-3734-4234-b433-303563613735/app_store_2.png",
  },

  googlePlay: {
    link: googlePlayLink,
    imageUrl: "https://static.tildacdn.com/tild3734-6365-4338-a238-633363353230/play_market.png",
  },
};
