import { getOAuthRedirectUrl } from "./utils/platform";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { StoreLinkWithImage } from "./Components/StoreLinkWithImage";
import { setUTMParamsToLink } from "./utils/setUTMParamsToLink";
import { stores } from "./constants";

function OAuth() {
  const { provider } = useParams();
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");

  useEffect(() => {
    window.location = getOAuthRedirectUrl(provider, code);
  }, [code, provider]);

  return (
    <div className="container__child__links">
      {Object.keys(stores).map((storeKey) => (
        <StoreLinkWithImage
          link={setUTMParamsToLink(stores[storeKey].link, searchParams)}
          img={stores[storeKey].imageUrl}
        />
      ))}
    </div>
  );
}

export default OAuth;
