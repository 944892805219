import { allowedUTMParams } from "../constants";

export const setUTMParamsToLink = (parentURL, searchParams) => {
  const newURL = new URL(parentURL); // we getting string in parentURL, that is why need this line

  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of searchParams.entries()) {
    if (allowedUTMParams.includes(key)) {
      newURL.searchParams.set(key, value);
    }
  }

  return newURL;
};
