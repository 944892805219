import { platforms } from "../common/constants";
import { config } from "../common/confg";

export const getOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return platforms.android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return platforms.ios;
  }

  return platforms.other;
};

export const getOAuthRedirectUrl = (provider, code) => {
  if (getOS() === "Android") {
    return `${config.androidAppName}://oauth/${provider}/${code}`;
  }

  if (getOS() === "iOS") {
    return `${config.iosAppName}://oauth/${provider}/${code}`;
  }

  return "/";
};
