import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { config } from "./common/confg";
import { getOS } from "./utils/platform";
import { StoreLinkWithImage } from "./Components/StoreLinkWithImage";
import { setUTMParamsToLink } from "./utils/setUTMParamsToLink";
import { stores } from "./constants";

function App() {
  const [searchParams] = useSearchParams();

  // TODO: Need changing realization
  useEffect(() => {
    const link = searchParams.get("link");

    if (link) {
      window.location = searchParams.get("link");
    }

    // if (getOS() === "Android") {
    //   window.location = config.androidLink;
    // }

    // if (getOS() === "iOS") {
    //   window.location = config.iosLink;
    // }
  });

  return (
    <div className="container__child__links">
      {Object.keys(stores).map((storeKey) => (
        <StoreLinkWithImage
          link={setUTMParamsToLink(stores[storeKey].link, searchParams)}
          img={stores[storeKey].imageUrl}
        />
      ))}
    </div>
  );
}

export default App;
